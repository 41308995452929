.contact .icons-container {
    margin-bottom: 3rem;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 2rem;
}

.contact .icons-container .icons {
    text-align: center;
    padding: 3rem 2rem;
    border: 0.1rem solid #c700ff;
    background: #730093;
}

.contact .icons-container .icons i {
    height: 6rem;
    width: 6rem;
    line-height: 6rem;
    font-size: 2rem;
    border-radius: 50%;
    margin-bottom: .5rem;
    color: #fff;
    background: #6400b4;
}

.contact .icons-container .icons h3 {
    font-size: 2rem;
    text-transform: capitalize;
    color: #ffffff;
    padding: .5rem 0;
}

.contact .icons-container .icons p {
    font-size: 1.5rem;
    line-height: 2;
    color: #ffffff;
}

.contact .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 2rem;
}

.contact .row .image {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
    flex: 1 1 40rem;
}

.contact .row .image img {
    width: 100%;
    object-fit: cover;
}
.icons a{
    color: white;
    font-weight: bold;
    font-size: 15px;
    background: rgba(255, 137, 99, 0.61);
    padding: 8px 20px;
    border-radius: 10px;
}
.icons a:hover{
    background: rgba(255, 181, 156, 0.61);

}
.contact .row form {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
    flex: 1 1 40rem;
    border: 0.1rem solid #ff0097;
    padding: 2rem;
}

.contact .row form h3 {
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #ffffff;
    padding-bottom: 1rem;
}

.contact .row form .box {
    width: 100%;
    padding: 1.2rem 1.4rem;
    border: 0.1rem solid #006dff;
    font-size: 1.6rem;
    margin: .7rem 0;
}

.contact .row form .box:focus {
    background: #2f092c;
    color: #fff;
}

.contact .row form .box:focus::-webkit-input-placeholder {
    color: #eee;
}

.contact .row form .box:focus:-ms-input-placeholder {
    color: #eee;
}

.contact .row form .box:focus::-ms-input-placeholder {
    color: #eee;
}

.contact .row form .box:focus::placeholder {
    color: #eee;
}

.contact .row form textarea {
    height: 15rem;
    resize: none;
}