.courses .box-container {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap: 2rem;
}

.courses .box-container .box .image img {
    border: 0.1rem solid #b04b00;
    border-bottom: none;
}
.box .image img{
    object-fit: contain!important;
    padding: 20px;
}
.courses .box-container .box.hide {
    display: none;
}


.courses .box-container .box .image {
    height: 25rem;
    overflow: hidden;
    position: relative;
}

.courses .box-container .box .image img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.courses .box-container .box .image h3 {
    font-size: 1.5rem;
    text-transform: capitalize;
    color: #444;
    position: absolute;
    top: 1rem;
    left: 1rem;
    padding: .5rem 1.5rem;
    background: #fff;
}

.courses .box-container .box .content {
    padding: 2rem;
    text-align: center;
    border: 0.1rem solid #b04b00;

    border-top:0.1rem solid #750606;
}

.courses .box-container .box .content h3 {
    font-size: 2rem;
    text-transform: capitalize;
    color: #ffffff;
}

.courses .box-container .box .content p {
    padding: 1rem 0;
    font-size: 1.6rem;
    line-height: 2;
    color: #ffffff;
}

.courses .box-container .box .content .icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 0.1rem solid #cec400;
}

.courses .box-container .box .content .icons span {
    font-size: 1.5rem;
    text-transform: capitalize;
    color: #ffffff;
}

.courses .box-container .box .content .icons span i {
    color: #cbcbcb;
    padding-right: .5rem;
}

.courses .load-more {
    margin-top: 2rem;
    text-align: center;
}

/*detail*/
.d-image{
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    margin-top: 9px;
}
.d-image img {
    width: 100%;
    height: 500px;
    border-radius: 15px;
}
.bgrs{
    background-color: #e8fff8;
}
.i-cent{
    width: 100%;
    display: flex;
    justify-content: center;
}
.course-info{
    width: 87%;
    display: flex;
    overflow: scroll;

    justify-content: center;
    height: 300px;
    background-color: #5eb6b6;
    margin-top: 10px;
    border-radius: 25px;

    margin-bottom: 40px;
}
.course-info h2{
    color: white;
    margin-top: 25px;
    text-align: center;
    margin-right: 70px;
    margin-left: 70px;
}

.h2-text{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    font-size: 24px;
}


@media(max-width:1400px)
{
    .course-info{
    }
    .d-image img {
        width: 100%;
        height: 200px;
    }
}
@media(max-width:900px)
{
   .course-info{
       overflow: scroll;
   }
    .d-image img {
        width: 100%;
        height: 200px;
    }
}
.cards {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 30px 0 30px 0;
    border: none;
    background-color: rgba(71, 0, 91, 0.53);
}
.cards .card-header {
    padding: 50px 50px 30px 50px;
    background-color: #47005b;
    margin-bottom: 40px;
}
.cards .card-header p {
    color: white;
}
.c-t{
    color: black;
}
.container-custom{
    padding: 0 5rem;
}
.cards .card-header .d-flex .headerImage {
    border: 1px solid rgba(0, 0, 0, 0.11);
    border-radius: 50%;
}
.works h3{
    color: white;
}
.skills h3{
    color: white;
}
.skills p{
    color: white;
}
.cards .card-header{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}
.cards .card-header .d-flex .headerImage img {
    width: 150px;
    object-fit: cover;
    height: 150px;
    border-radius: 50%;
}
.cards .card-header .d-flex h1 {
    color: white;
    align-items: center;
    display: flex;
}
.cards__btn{
    color: white;
    padding: 15px;
    font-size: 16px;
    cursor: pointer;
}
.cards__btn:hover{
    color: #ff00e9;
}
.cards .card-header .salaryInfo {
    display: grid;
}
.cards .card-header .salaryInfo p {
    margin-bottom: 10px;
    width: -moz-fit-content;
    width: fit-content;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 4px 10px;
    font-weight: 500;
    font-size: 12px;
}
.cards .apply {
    justify-content: flex-end;
    display: flex;
    top: -20px;
    position: relative;
    margin-right: 60px;
}

.cards .apply :focus {
    outline: none;
}
.cards .card-body .top_infos p {
    margin-right: 20px;
    margin-left: 20px;
    color: #ffffff;
    display: list-item;
}
.cards .card-body {
    padding-top: 4rem;
    padding-bottom: 4rem;
}


.cards .card-body .body_infos .works p {
    display: list-item;
    color: #ffffff;
    width: 75%;
}
.cards .card-body .body_infos .skills p {
    display: list-item;
    margin-left: 20px;
    width: 75%;
}

.cards .card-footer {
    background-color: #F8F8F9;
}
.cards .card-footer h6 {
    top: 5px;
    margin-right: 30px;
    position: relative;
}
.works h3{
    font-size: 22px;
}
.works p{
    font-size: 16px;
}
.skills h3{
    font-size: 22px;
}
.skills p{
    font-size: 16px;
}

.card-footer button{
    padding: 10px 50px;
    font-size: 16px;
    background-color: #c7c7c7;
}
.xalid-blyaaa{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.xalid-blyaaa h2{
    color: white;
    font-size: 23px;
}