@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;1,100&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style-type: none;
}

* {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    border: none;
    text-decoration: none;
    -webkit-transition: all .1s linear;
    transition: all .2s linear;
}

img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

body {
    background-color: #020F1F;

}



html::-webkit-scrollbar {
    width: 1rem;
}

html::-webkit-scrollbar-track {
    background: transparent;
}

html::-webkit-scrollbar-thumb {
    background: #f7941e;
}

section {
    padding: 5rem 10%;
}