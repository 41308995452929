.header {
    width: 100%;
    position: sticky;
    padding: 0 2rem;
    background-color: #000000;
    z-index: 100;
    top: 0;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(121, 121, 121, 0.1);
}

.box {
    height: 95px;
    cursor: pointer;
    display: flex;
    padding: 0 5rem;
    justify-content: space-between;
}

.logo_image {
    display: flex;
    align-items: center;
}

.logo_image img {
    height: 90px;
    width: 90px;
    object-fit: contain;
    margin-right: 10px;
}



.menu {
    display: flex;
    align-items: center;
    font-size: 22px;
    line-height: 28px;
    gap: 30px;
}
.menu li a{
    color: #ffffff;
    font-size: 24px;
    cursor: pointer;
    font-weight: 300;
}
.menu li a:hover{
    color: #ff9462;
}
.menu li a:focus{
   color: #ff0000;
}


.mobile_btn {
    display: none;
    color: purple;
}


@media (max-width: 996px) {
    .mobile_btn {
        display: block;
        position: absolute;
        right: 10px;
        top: 32px;
        cursor: pointer;
        z-index: 10;
    }
    .header{
        padding: 0;
    }
    .box {
        padding: 0 1rem;
    }


    .menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        left: -100%;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        background-color: #000000;
        z-index: 10;
        transition: left 0.9s;
    }

    .active {
        left: 0;
    }

    .menu a {
        font-size: 28px;
    }
}