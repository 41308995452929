

.welcome{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
}
.welcome h2{
    font-size: 26px;
    font-weight: 300;
    color: white;
}
.welcome-text{
    width: 70%;
}
.welcome-text p {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
    color: white;
    width: 100%;
}
.welcome-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}
.w-card{
    background-color:white;
    width: 250px;
    border: 1px solid rgba(192, 192, 192, 0.29);
    margin:7px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    height: 130px;

}

.w-card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.subjects .box-container {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 2fr));
    gap: 1rem;
}

.subjects .box-container .box {
    padding: 2rem 2rem;
    text-align: center;
    border: 0.1rem solid #ff0000;
    width: 100%;
    background: rgba(242, 214, 255, 0.27);
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}
.subjects .box-container .box h3{
    color: white!important;
}
.subjects .box-container .box:hover {
    background: rgba(255, 137, 99, 0.61);
}

.blck-imgsss img{
    filter: brightness(50%);
}
.subjects .box-container .box:hover{
    color: #fff;
}

.subjects .box-container .box:hover h3 {
    color: #fff;
}

.subjects .box-container .box:hover p {
    color: #eee;
}

.subjects .box-container .box img {
    height: 10rem;
    width: 100%;
    padding: 10px;
    object-fit: contain;
    margin-bottom: .7rem;
    border: 0.1px solid silver;
}

.subjects .box-container .box h3 {
    font-size: 14px;
    text-transform: capitalize;
    color: #444;
    padding: .5rem 0;
    margin-top: 20px;

}

.subjects .box-container .box p {
    font-size: 1.5rem;
    line-height: 2;
    color: #777;
}

.container-s {
    display: flex;
    width: 100%;
    padding: 0 20rem;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.container-scl{
    background-image: url("https://www.giz.de/static/en/media/schueler-1920.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 700px;
}

.black-phone{
    width: 44vw;
    height: 700px;
    background-color: rgba(0, 0, 0, 0.45);
    background-size: cover;
    background-repeat: no-repeat;
}

.image-text__container {
    display: flex;
    width: 100%;
    height: 100%;
    align-content: center;
    padding: 0 13rem;
    flex-direction: column;
}

.image-text__container h2 {
    color: white;
    font-size:46px;
    margin-top: 230px;
    font-family: "Leelawadee UI"
}
.image-text__container p {
    color: white;
    font-size:22px;
    margin-top: 10px;
    font-family: "Leelawadee UI"

}
.image-text__container h3{
    color: white;
    background-color: rgba(163, 202, 255, 0.63);
    width: 55%;
    align-items: center;
    text-align: center;
    font-weight: 100;
    padding: 6px;
    font-family: "ui-rounded";

}

@media (max-width: 996px) {
    .container-scl{
        background-image: url("https://www.giz.de/static/en/media/schueler-1920.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        height: 300px;
    }

    .black-phone{
        width: 0;
        height: 0;
        background-color: rgba(0, 0, 0, 0.45);
        background-size: cover;
        background-repeat: no-repeat;
    }

    .image-text__container {
        display: none;
    }
}
@media (max-width: 540px) {
    .container-scl{
        background-image: url("https://www.giz.de/static/en/media/schueler-1920.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        height: 200px;
    }

}

@media (max-width: 1400px) {
    .image-text__container h3{
        width: 100%;
    }

}

.card {
    border-radius: 10px;
    background: #413a45;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
    padding: 10px;
    display: flex;
    transition: 0.5s all ease-in-out;
}

.card-header img {
    width: 100%;
    height: 200px;
    object-fit: contain;
}
.consius{
    padding: 10rem 3rem;
}

.text__cont{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 50px;
}
.text__cont h2{
    font-size: 22px;
    font-weight: bold;
    color: white;
}
.text__cont p{
    color: white;
    font-size: 16px;
}


.tag {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
}
.tag-teal {
    background-color: #47bcd4;
}
.tag-purple {
    background-color: #5e76bf;
}
.tag-pink {
    background-color: #cd5b9f;
}

.card-body p {
    font-size: 13px;
    margin: 0 0 40px;
}
.card-body-s{
    width: 100%;
    min-height:50px;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-body-s h1{
    font-size: 22px;
    margin-left: 8px;
    color: white;
}
.bg-chng{
    filter: brightness(90%);
}
.card-footer{
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.user {
    display: flex;
    margin-top: auto;
}

.user img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.user-info h5 {
    margin: 0;
}
.user-info small {
    color: #545d7a;
}

.home img {
    width: 100%;

}

.img-container {
    position: relative;
    text-align: center;
    color: white;
}
.swiper-wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.center {
    position: absolute;
    top: 40%;
    display: flex;
    justify-content: center;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.center h1 {
    color: white;
    font-size: 32px;
}

@media (max-width: 1200px) {
    .header {
        padding: 2rem 5%;
    }

    section {
        padding: 3rem 5%;
    }
}


@media (max-width: 1440px) {
    .subjects .box-container .box img {
        height: 10rem;
        width: 100%;
        margin-bottom: .7rem;
    }
}
@media (max-width: 996px) {
    .welcome-text{
        width: 100%;
        text-align: center;
    }
}


.section-head {
    margin-bottom: 40px;

}
.section-head h4 {
    position: relative;
    padding:0;
    color:#f91942;
    line-height: 1;
    letter-spacing:0.3px;
    font-size: 34px;
    font-weight: 700;
    text-align:center;
    text-transform:none;
    margin-bottom:30px;
}
.section-head h4:before {
    content: '';
    width: 60px;
    height: 3px;
    background: #f91942;
    position: absolute;
    left: 0px;
    bottom: -10px;
    right:0;
    margin:0 auto;
}
.section-head h4 span {
    font-weight: 700;
    padding-bottom: 5px;
    color: #ffffff;
}
p.service_text{
    color:#cccccc !important;
    font-size:16px;
    line-height:28px;
    text-align:center;
}
.section-head p, p.awesome_line{
    color:#818181;
    font-size:16px;
    line-height:28px;
    text-align:center;
}

.extra-text {
    font-size:34px;
    font-weight: 700;
    color:#2f2f2f;
    margin-bottom: 25px;
    position:relative;
    text-transform: none;
}
.extra-text::before {
    content: '';
    width: 60px;
    height: 3px;
    background: #f91942;
    position: absolute;
    left: 0px;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
}
.extra-text span {
    font-weight: 700;
    color:#f91942;
}
.item {
    background: #fff;
    text-align: center;
    padding: 30px 50px;
    -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
    box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    border:5px solid rgba(0, 0, 0, 0.07);
    margin-bottom: 30px;
     max-height: 300px;
    min-height: 150px;
    -webkit-transition: all .5s ease;
    width: 100%;
    transition: all .5s ease;

}


.item:hover{
    background: rgba(255, 137, 99, 0.61);
    box-shadow:0 8px 20px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    color: white;
}
.item:hover .item, .item:hover span.icon{
    background:#fff;
    border-radius:10px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}
.item:hover h6, .item:hover p{
    color:#fff;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}


.item p{
    font-size:16px;
    line-height:26px;
}

.item h6 {
    color:#2f2f2f;
    display: flex;
    font-size: 18px;
}

.mission p {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
}

@media (max-width: 996px) {
   .a-text{
       width: 100%;
   }
    .articles{
        display: none;
    }

}

.test-text{
    color: white;
    font-size: 16px;
    font-weight: 300;
}

.mt-7{
    margin-top: 70px;
}
.ml-20{
    margin-left: 200px;
}
.ml-10{
    margin-left: 1000px;
}
.ml-7{
    margin-left: 70px;
}
